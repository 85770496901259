.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  margin-bottom: 30px;
}

p {
  line-height: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #D6C47C;
  text-decoration: none;
}
.App-link:hover {
  opacity: 0.8;
}